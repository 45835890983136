import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import {  Button} from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles  } from '@material-ui/core/styles';
import ProviderService from "../../Services/ProviderService";
import {compareIgnoreCase} from "../../utils/Utils";
import {isNotEmpty} from "../../utils/Validator";
import AddUserAddTINTable from "./AddUserAddTINTable";
import LoadingMsgBar from "../LoadingMsgBar";
import GlobalMessage from "../GlobalMessage.js";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,       
    },
    label: {
        fontWeight: 'bold',
        textAlign: 'left',
        whiteSpace: 'noWrap',			
      }, 
      table: {
        width: 550,
      } ,
      
}));

export default function AddUserAddTINDialog(props) {
    const classes = useStyles(); 
    const [taxId, setTaxId] = React.useState();
    const [providerType, setProviderType] = React.useState("P")
    const [provId, setProvId] = React.useState();
    const [facilityName, setFacilityName] = React.useState();
    const [firstName, setFirstName] = React.useState();
    const [lastName, setLastName] = React.useState();
    const [taxIdErrorMsg, setTaxIdErrorMsg]= React.useState();
    const [provIdErrorMsg, setProvIdErrorMsg]= React.useState();
    const [providers, setProviders] = React.useState([]);
    const [providersFiltered, setProvidersFiltered] = React.useState([]);
    const [selectedPrv, setSelectedPrv] = React.useState([]);
    const [selTaxId, setSelTaxId] = React.useState();
    const [selProvId, setSelProvId] = React.useState();
    const [selProvName, setSelProvName] = React.useState();
    const [isBusyMsg, setIsBusyMsg] = React.useState("");
    const [searchDone,setSearchDone]= React.useState(false);
    const [selectMsg, setSelectMsg] = React.useState("Note: Please select one.");
    const [errorMsg, setErrorMsg] = React.useState("");


      const handleReset = () => {
        setTaxId("");
        setProviders([]);
        setProvidersFiltered([]);
        setProviderType('P');
        setProvId('');
        setFacilityName('');
        setFirstName('');
        setLastName('');
        setTaxIdErrorMsg('');
        setProviders([]);
        setSelectedPrv([]);
        setIsBusyMsg('');
        setErrorMsg('');
        setProvIdErrorMsg('');
        setSearchDone(false);
        setSelectMsg("Note: Please select one.")
      }
      const handleSearch = () => {
        setTaxIdErrorMsg('');
        setProvIdErrorMsg('');
        if(validate(taxId)){
            setProviders([]);
            setProvidersFiltered([]);
            setIsBusyMsg('Loading Providers with selected Tax ID...');
            getProviders();
        }
      };
      
      const handleClose= () => {
        handleReset();
        props.onClose();
      }

      const handleAdd= () => {
        if(isNotEmpty(selectedPrv)){
            let tempAdd=[];
            tempAdd.push(selectedPrv);
            props.onDataReceived(selTaxId, selProvId, selProvName);
            handleReset();
            props.onClose();
      }else{
        setSelectMsg("Error: Please select one.")
      }

      }

      const  handleProviderTypeSelected = (event) => {      
        setProviderType(event.target.value);
        setFacilityName('');
        setFirstName('');
        setLastName('');
        setProvId('');
      }; 

      
      const handleEnter = () => {
        setTaxId("");
        setProviders([]);
        setProvidersFiltered([]);
        // Perform any initial setup here
      }

      const validate = () => {
        let isValid = true;
        let provIdTrim = provId.trim();
      
            if (taxId === 0) {
                setTaxIdErrorMsg('Error: Enter a Tax ID Number (TIN)')
                isValid = false
            }
            //const exp = new RegExp("^(?=.*[0-9])")
            const exp = new RegExp('^[0-9]+$');             
            if ( exp.test(taxId) !== true) {
                setTaxIdErrorMsg(`Error: Enter a valid Tax ID Number (TIN)`)
                isValid = false
            }
    
            if (taxId.length <9) {
                setTaxIdErrorMsg('Error: Enter a 9-digit Tax ID Number (TIN)')
                isValid = false
            }
            
            else if(isNotEmpty(provIdTrim) || (provIdTrim.length !== 0)){
                const exp = new RegExp("^[0-9]+$")//only numbers
                if ( exp.test(provIdTrim) === false) {
                    setProvIdErrorMsg(`Error: Enter a valid Provider ID Number`);
                    isValid = false
                }
            } 
    
            
            return isValid
    }

     const buildQuery = () =>{
        let queryStr=taxId.trim() + '?prv-type=' + providerType;
        let tempStr='';
        if(isNotEmpty(provId)){
            tempStr= queryStr.concat( '&prv-id=' + provId.trim() ) ;
            queryStr = tempStr;
        }
       
       if(isNotEmpty(facilityName)){
         tempStr = queryStr.concat('&facility-nm=' + facilityName.trim());
         queryStr = tempStr;
        }else if(isNotEmpty(firstName) && isNotEmpty(lastName)){
            tempStr = queryStr.concat('&phy-first-nm=' + firstName.trim()) ;
            queryStr = tempStr;
            tempStr = queryStr.concat('&phy-last-nm=' + lastName.trim() );
            queryStr = tempStr;
        }
        else if(isNotEmpty(firstName) || isNotEmpty(lastName)){
            if(isNotEmpty(firstName)){
                tempStr = queryStr.concat( '&phy-first-nm=' + firstName.trim() );
                queryStr = tempStr;
            }
            if(isNotEmpty(lastName)){
                tempStr = queryStr.concat( '&phy-last-nm=' + lastName.trim()  );
                queryStr = tempStr;
            } 
        }

        return queryStr;
     }
    
    const getProviders = () => {
        setSearchDone(false);
        setProviders([]);
        setProvidersFiltered([]);
        setErrorMsg('');
        let quesryStr = buildQuery();
        //'461322238?phy-first-nm=Sta&prv-id=1578424'

        ProviderService.getProviders(quesryStr)
            .then((response) => {
             if (isNotEmpty(response.data)) {
                    let data = response.data;
                    for (let i = 0; i < data.length; i++) {
                        data[i].selected = false
                    }

                   data.sort((a, b) => compareIgnoreCase(a.prvName, b.prvName));
                    setProviders(data);
                    setProvidersFiltered(data);
                    setIsBusyMsg('');
                    setSearchDone(true);
                } 
                else{
                    setIsBusyMsg('');
                    setSearchDone(true);
                    setErrorMsg('No Providers found for the selected Tax ID')
                }

            }).catch((error) => {
            setIsBusyMsg('');
            setSearchDone(true);
            setErrorMsg('Error:  An error has occurred.  Please try again or contact Customer Service.');
            console.log('Error GETTING THE PROVIDERS' + error);
        })
    };
    const setFilter = (filtered) => {
        if(filtered && filtered.length >= 0){
            setProvidersFiltered(filtered);
        }else{
                setProvidersFiltered([]); 
            }
        }

 

 const setSelectedPrvId = (taxId, prvId, prvName, checked) => {
    //console.log(row);
    setSelectedPrv([]);
    if(checked){
        setSelectedPrv(taxId);
        setSelTaxId(taxId);
        setSelProvId(prvId);
        setSelProvName(prvName);
    
        const selectedItems = providers.map(item => 
            item.prvId === prvId ? { ...item, selected: true } : {...item, selected: false}
        );
        setProviders(selectedItems);
        setProvidersFiltered(selectedItems);
    }
    else{
        const updatedItems = providers.map(item => 
            item.prvId === prvId ? { ...item, selected: false } : item
          );
          setProviders(updatedItems);
          setProvidersFiltered(updatedItems);
    }
    console.log(selectedPrv);
}
    const getTable = () => {    
        if (providers.length > 0) {
            return (
                <AddUserAddTINTable clients={providersFiltered} setFilter={setFilter}
                             setPrvId={setSelectedPrvId} unfilteredUsers={providers}
                                       cnt={providers.length} msg={selectMsg}/>
            );
        }
    };

    const showSuccessErrorMessage = () =>{
        if(searchDone=== true){
        return(                    
                <GlobalMessage  message={errorMsg} />         
            );
        }
    }
  
    return (
        <div>

    <Dialog
                  onClose={props.onClose} open={props.open} onEnter={handleEnter}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.dialog} fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">Add TIN Provider
                    </DialogTitle>
                    <DialogContent>
                  
                    <div className='App-content Manage-tins App-font'>
                   
                    <h1 className='App-page-label'>Enter TIN + any 1 of the options listed</h1>
                    <LoadingMsgBar loadMsg={isBusyMsg}/>
                    {showSuccessErrorMessage()}
                 
                    <div>
                        <TextField
                            id="outlined-basic"
                            error={taxIdErrorMsg}
                            helperText={taxIdErrorMsg}
                            required
                            inputProps={{ maxLength: 9 }}
                            defaultValue=""
                            label="Tax ID Number (TIN)"
                            margin="normal"
                            variant="outlined"
                            onChange={event => setTaxId(event.target.value)}
                            value={taxId}
                        />


                    </div>
                    <div>
                    <FormLabel required component="legend">Provider Type</FormLabel>
                            <RadioGroup aria-label="position" defaultValue={providerType} value={providerType} name="position"
                                   onChange={handleProviderTypeSelected}   row>
                                <FormControlLabel
                                    value='P'
                                    control={<Radio color="primary" />}
                                    label="Physician"
                                    labelPlacement="end"
                                    name='Physician'
                                />
                                <FormControlLabel
                                    name='facility'
                                    value='F'
                                    control={<Radio color="primary" />}
                                    label="Facility"
                                    labelPlacement="end"

                                />
                            </RadioGroup>    
                  
                    </div>
                    <div>
                    <TextField
                            id="outlined-uncontrolled"
                            label="Provider ID Number"
                            error={provIdErrorMsg}
                            helperText={provIdErrorMsg}
                            defaultValue=" "
                            inputProps={{ maxLength: 8 }}
                            margin="normal"
                            variant="outlined"
                            onChange={event => setProvId(event.target.value)}
                            value={provId}
                            
                        />
                    </div>
                   
                    {(providerType === "P") ?
                    <div>
                        <TextField
                            id="outlined-basic"
                            inputProps={{ maxLength: 9 }}
                            defaultValue=""
                            label="LastName"
                            margin="normal"
                            variant="outlined"
                            onChange={event => setLastName(event.target.value)}
                            value={lastName}
                        />
                         <TextField
                            id="outlined-basic"
                            inputProps={{ maxLength: 9 }}
                            defaultValue=""
                            label="FirstName"
                            margin="normal"
                            variant="outlined"
                            onChange={event => setFirstName(event.target.value)}
                            value={firstName} style={{paddingLeft:'5px'}}
                        />
                    </div>
                    :
                    <div>
                    <TextField
                        id="outlined-basic"
                        inputProps={{ maxLength: 9 }}
                        defaultValue=" "
                        label="Facility Name"
                        margin="normal"
                        variant="outlined"
                        onChange={event => setFacilityName(event.target.value)}
                        value={facilityName}
                    />
                </div>}
                    
                <div className='Manage-tins-btn-ln'>
                    <Button   onClick={handleSearch}>
                        Search
                    </Button>

                    <Button  variant="outlined" onClick={handleReset}>
                       Reset
                    </Button>

                    <Button  variant="outlined" onClick={handleAdd}>
                       Add
                    </Button>
                    
                    
                    <Button  variant="outlined" onClick={handleClose}>
                       close
                    </Button>
                    </div>      
                    <div className='ClntLstResponse'>
                    {getTable()}
                    </div>
                    <div >
                  
                  </div>
                </div>
</DialogContent>
                </Dialog>
       </div>
    );
}