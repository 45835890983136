import styles from "./ContentToggle.module.css";

import React, { useState, useEffect } from 'react';

const ContentToggle = ({ moreLabel, lessLabel, children, expanded, onExpand, onCollapse, labelClass, disabled=false }) => {
  const [isExpanded, setIsExpanded] = useState(expanded || false);

  useEffect(() => {
    if (expanded !== undefined) {
      setIsExpanded(expanded);
    }
  }, [expanded]);

  const toggleExpand = () => {
    const newExpandedState = !isExpanded;
    setIsExpanded(newExpandedState);
    
    if (newExpandedState) {
      onExpand && onExpand();
    } else {
      onCollapse && onCollapse();
    }
  };

  return (
    <div className="content-toggle">
      <button disabled={disabled} onClick={toggleExpand} className={`${styles.linkStyle} ${labelClass ? labelClass : ""}`}>
        {isExpanded ? <><span>{lessLabel} &#11206;</span> </> : <> <span>{moreLabel} &#11208;</span></>}
      </button>
      {isExpanded && (
        <div className="content-toggle__content">
          {children}
        </div>
      )}
    </div>
  );
};

export default ContentToggle;
