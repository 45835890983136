import styles from "./ClaimantInformationInputComponent.module.css";

import React, { Component } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import {
  isNotEmpty,
  isEmpty,
  isDate,
  isFutureDate,
} from "../../../utils/Validator.js";
import {
  getDateRange,
  setFocusOnFirstError,
  addParam,
  addTrimmedParam,
  trimValue,
} from "../../../utils/Utils.js";
import { toYYYYMMDD } from "../../../utils/Format.js";
import BillSearchService from "../../../Services/BillSearchService";
import AuthenticationService from "../../../Services/AuthenticationService";
import "./BillSearch.css";
import { setInitialFocus } from "../../../utils/Utils";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import MultiSelectCheckbox from "../../MultiSelectCheckbox";
import ContentToggle from "../../ContentToggle.js";

window.analytics = { pageName: "Bill Search: Claimant Information" };
class ClaimantInformationInputComponent extends Component {
  constructor(props) {
    super(props);

    this.state = this.initializeState();
    this.errorListRef = React.createRef().current = [];
  }

  initializeState() {
    const initialState = {
      processingMessage: "Please wait while we process your request.",
      wasSubmitted: false,

      tinList: [], // an array of tins the user has access to, selects from these
      selectedFtins: [],
      claimantFirstName: "",
      claimantLastName: "",
      approximateServiceDate: null,
      claimantDateOfBirth: null,
      claimNumber: "",
      documentId: "",
      billControlNumber: "",
      searchBy: "claimNumber",
    };

    return initialState;
  }

  handleResetButtonOnClick = () => {
    this.clearForm();
  };

  clearForm() {
    this.clearFields();
    this.clearErrorMessages();
    this.props.clearSearchResults();
  }

  clearFields() {
    this.setState({
      approximateServiceDate: null,
      billControlNumber: "",
      claimantDateOfBirth: null,
      claimantFirstName: "",
      claimantLastName: "",
      claimNumber: "",
      documentId: "",
      selectedFtins: [],
    });
  }

  validate = () => {
    this.props.enableDisableSearch(false);
    let syntaxResults = this.doSyntaxValidation();

    if (!syntaxResults.isValid) {
      this.setGlobalErrorMessage(
        'Please enter required information for search.  Refer to "Search Help" for valid search combinations.'
      );

      this.showMessages(syntaxResults.messageList);
      this.props.enableDisableSearch(true);

      setFocusOnFirstError();
    }

    return syntaxResults.isValid;
  };

  setGlobalErrorMessage(message) {
    this.props.updateGlobalMessage(message, "error");
  }

  clearGlobalErrorMessage() {
    this.props.updateGlobalMessage("", "error");
  }

  showMessages(messageList) {
    messageList.forEach((message) => {
      this.showErrorMessage(message.field, message.message);
    });
  }

  showErrorMessage(fieldName, errorMessage) {
    this.setState({
      [fieldName]: errorMessage,
    });
  }

  addErrorMessage = (fieldName, message) => {
    this.errorListRef.push({
      field: fieldName + "Error",
      message: "ERROR: " + message,
    });
  };

  clearErrorMessage(fieldName) {
    this.setState({
      [fieldName]: "",
    });
  }

  validateClaimSearch() {
    this.errorListRef = [];
    let messageList = [];
    let isValid = false;

    if (isEmpty(this.state.selectedFtins)) {
      this.addErrorMessage("selectedFtins", "Select up to 5 TINS");
    }

    if (isEmpty(this.state.claimNumber)) {
      this.addErrorMessage("claimNumber", "Enter a claim number");
    }

    if (isEmpty(this.state.approximateServiceDate)) {
      this.addErrorMessage(
        "approximateServiceDate",
        "Enter an approximate date of service."
      );
    }

    if (isNotEmpty(this.state.approximateServiceDate)) {
      if (!isDate(this.state.approximateServiceDate)) {
        this.addErrorMessage(
          "approximateServiceDate",
          "Enter a valid Approximate Date of Service"
        );
      } else if (isFutureDate(this.state.approximateServiceDate)) {
        this.addErrorMessage(
          "approximateServiceDate",
          "Approximate Date of Service cannot be a future date"
        );
      }
    }

    messageList = this.errorListRef;

    isValid = messageList.length === 0;

    return { isValid: isValid, messageList: messageList };
  }

  validateClaimantSearch() {
    this.errorListRef = [];
    let messageList = [];
    let isValid = false;

    if (isEmpty(this.state.selectedFtins)) {
      this.addErrorMessage("selectedFtins", "Select up to 5 TINS");
    }

    if (isEmpty(this.state.claimantFirstName)) {
      this.addErrorMessage("claimantFirstName", "Enter claimant's first name");
    }

    if (isEmpty(this.state.claimantLastName)) {
      this.addErrorMessage("claimantLastName", "Enter claimant's last name");
    }

    if (isEmpty(this.state.claimantDateOfBirth)) {
      this.addErrorMessage(
        "claimantDateOfBirth",
        "Enter claimant's date of birth"
      );
    }

    if (isNotEmpty(this.state.claimantDateOfBirth)) {
      if (!isDate(this.state.claimantDateOfBirth)) {
        this.addErrorMessage(
          "claimantDateOfBirth",
          "Enter a valid Claimant Date of Birth"
        );
      } else if (isFutureDate(this.state.claimantDateOfBirth)) {
        this.addErrorMessage(
          "claimantDateOfBirth",
          "Claimant Date of Birth cannot be a future date"
        );
      }
    }

    if (isEmpty(this.state.approximateServiceDate)) {
      this.addErrorMessage(
        "approximateServiceDate",
        "Enter an approximate date of service."
      );
    }

    if (isNotEmpty(this.state.approximateServiceDate)) {
      if (!isDate(this.state.approximateServiceDate)) {
        this.addErrorMessage(
          "approximateServiceDate",
          "Enter a valid Approximate Date of Service"
        );
      } else if (isFutureDate(this.state.approximateServiceDate)) {
        this.addErrorMessage(
          "approximateServiceDate",
          "Approximate Date of Service cannot be a future date"
        );
      }
    }

    messageList = this.errorListRef;

    isValid = messageList.length === 0;

    return { isValid: isValid, messageList: messageList };
  }

  validateBillSearch() {
    this.errorListRef = [];
    let messageList = [];
    let isValid = false;

    if (isEmpty(this.state.selectedFtins)) {
      this.addErrorMessage("selectedFtins", "Select up to 5 TINS");
    }

    if (
      isEmpty(this.state.billControlNumber) &&
      isEmpty(this.state.documentId)
    ) {
      this.addErrorMessage(
        "billControlNumber",
        "Enter Document ID or Bill Control Number"
      );
    }

    messageList = this.errorListRef;

    isValid = messageList.length === 0;

    return { isValid: isValid, messageList: messageList };
  }

  doSyntaxValidation() {
    if (this.state.searchBy === "claimNumber") {
      return this.validateClaimSearch();
    } else if (this.state.searchBy === "claimant") {
      return this.validateClaimantSearch();
    } else if (this.state.searchBy === "billNumber") {
      return this.validateBillSearch();
    }
  }

  isLessWithCard = (name) => {
    if (name) {
      const len = name.length;

      if (len <= 3) {
        if (name[len - 1] === "*") {
          return true;
        } else if (len < 3) {
          return true;
        }
      }
    }
    return false;
  };

  clearErrorMessages() {
    let errorFieldList = [
      "approximateServiceDateError",
      "billControlNumberError",
      "claimantDateOfBirthError",
      "claimantFirstNameError",
      "claimantLastNameError",
      "claimNumberError",
      "documentIdError",
      "selectedFtinsError",
    ];

    errorFieldList.forEach((errorFieldName) => {
      this.clearErrorMessage(errorFieldName);
    });

    this.clearGlobalErrorMessage();

    this.errorListRef = [];
  }

  componentDidMount() {
    this.loadTinsForLoggedInUser();
    this.props.enableDisableSearch(true);

    setInitialFocus(".claimantInformationInputComponent");
  }

  loadTinsForLoggedInUser() {
    this.loadTins(AuthenticationService.getLoggedInUserName());
  }

  loadTins = (userName) => {
    BillSearchService.findTinsForUser(userName).then(
      (response) => {
        let tins = [];
        response.data.forEach((tin) => {
          tins.push({ value: tin.ftin, label: tin.ftin });
        });
        this.setState({ tinList: tins });
      },
      (error) => {
        console.log(error);
        this.setGlobalErrorMessage(
          "Error: There was an error retrieving data. Please try again or contact Customer Service."
        );
      }
    );
  };

  // by the time this is called the form was validated but didn't trim values before validating
  getFormData() {
    let searchParams = null;
    let beginServiceDateStr = null;
    let endServiceDateStr = null;

    if (this.state.approximateServiceDate != null) {
      let [beginServiceDate, endServiceDate] = getDateRange(
        new Date(this.state.approximateServiceDate), 7);
      beginServiceDateStr = toYYYYMMDD(beginServiceDate);
      endServiceDateStr = toYYYYMMDD(endServiceDate);
    }

    let params = {};

    addTrimmedParam(params, "claimantFirstName", this.state.claimantFirstName);
    addTrimmedParam(params, "claimantLastName", this.state.claimantLastName);
    addTrimmedParam(params, "claimNumber", this.state.claimNumber);
    addTrimmedParam(params, "documentId", this.state.documentId);
    addTrimmedParam(params, "billControlNumber", this.state.billControlNumber);
    addParam(params, "claimantDateOfBirth", this.state.claimantDateOfBirth);
    addParam(params, "beginServiceDate", beginServiceDateStr);
    addParam(params, "endServiceDate", endServiceDateStr);

    searchParams = {
      isTinArray: true,
      tinArray: this.state.selectedFtins,
      searchParams: { ...params },
    };

    return searchParams;
  }

  handleSearchButtonOnClick = () => {
    let searchParams = null;
    this.clearErrorMessages();
    this.props.clearSearchResults();

    this.setState({ wasSubmitted: true });

    if (this.validate()) {
      searchParams = this.getFormData();
      this.props.handleSearch(searchParams);
    }
  };

  handleInputChange = (event, shouldTrim = false) => {
    let target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    let name = target.name;

    if (shouldTrim) {
      value = trimValue(value);
    }

    this.setState({
      [name]: value,
    });
  };

  handleContentToggleExpanded = (toggleName) => {
    this.setState({ searchBy: toggleName });
    this.clearErrorMessages();
    this.clearFields();
  };

  render() {
    return (
      <div className="claimantInformationInputComponent">
        <p
          className="App-error-font App-note-Field"
          style={{ paddingBottom: "0" }}
        >
          Note: Fields marked with an asterisk(*) are required.
        </p>

        <ContentToggle
          style={{ fontSize: "1rem", fontWeight: "bold" }}
          moreLabel={`Search By Claim Number`}
          lessLabel={`Search By Claim Number`}
          labelClass={styles.labelClass}
          onExpand={() => this.handleContentToggleExpanded("claimNumber")}
          expanded={this.state.searchBy === "claimNumber"}
          disabled={this.state.searchBy === "claimNumber"}
          onCollapse={() => console.log("collapsed")}
        >
          <div className="billSearchHelp">
            <Grid container spacing={3}>
              {/* Row 1 */}
              <Grid item xs={12}>
                <MultiSelectCheckbox
                  name="selectedFtins"
                  itemList={this.state.tinList}
                  label="Select Tax ID (5 Maximum)"
                  onChange={this.handleInputChange}
                  maxSelectedCount={5}
                  value={this.state.selectedFtins}
                  error={isNotEmpty(this.state.selectedFtinsError)}
                  helperText={this.state.selectedFtinsError}
                  required
                />
              </Grid>

              {/* Row 2 */}
              <Grid item xs={6}>
                <TextField
                  name="claimNumber"
                  label="Claim Number"
                  error={isNotEmpty(this.state.claimNumberError)}
                  helperText={this.state.claimNumberError}
                  value={this.state.claimNumber}
                  onBlur={(event) => this.handleInputChange(event, true)}
                  onChange={this.handleInputChange}
                  inputProps={{ maxLength: 40 }}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    error={isNotEmpty(this.state.approximateServiceDateError)}
                    helperText={this.state.approximateServiceDateError}
                    value={this.state.approximateServiceDate}
                    onChange={(date) => {
                      if (date instanceof Date) {
                        date.setHours(8, 0, 0, 0);
                        this.setState({ approximateServiceDate: date });
                      } else {
                        this.setState({
                          approximateServiceDate: null,
                        });
                      }
                    }}
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="Approximate Service Date (± 7 Days)"
                    fullWidth
                    disableFuture
                    placeholder="MM/DD/YYYY"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    required
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </div>
        </ContentToggle>

        <ContentToggle
          style={{ fontSize: "1rem", fontWeight: "bold" }}
          moreLabel="Search By Claimant"
          lessLabel="Search By Claimant"
          labelClass={styles.labelClass}
          onExpand={() => this.handleContentToggleExpanded("claimant")}
          expanded={this.state.searchBy === "claimant"}
          disabled={this.state.searchBy === "claimant"}
          onCollapse={() => console.log("collapsed")}
        >
          <div className="billSearchHelp">
            <Grid container spacing={3}>
              {/* Row 1 */}
              <Grid item xs={12}>
                <MultiSelectCheckbox
                  name="selectedFtins"
                  itemList={this.state.tinList}
                  label="Select Tax ID (5 Maximum)"
                  onChange={this.handleInputChange}
                  maxSelectedCount={5}
                  value={this.state.selectedFtins}
                  error={isNotEmpty(this.state.selectedFtinsError)}
                  helperText={this.state.selectedFtinsError}
                  required
                />
              </Grid>

              {/* Row 3 */}
              <Grid item xs={6}>
                <TextField
                  name="claimantFirstName"
                  label="Claimant First Name"
                  error={isNotEmpty(this.state.claimantFirstNameError)}
                  helperText={this.state.claimantFirstNameError}
                  value={this.state.claimantFirstName}
                  onBlur={(event) => this.handleInputChange(event, true)}
                  onChange={this.handleInputChange}
                  inputProps={{ maxLength: 40 }}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="claimantLastName"
                  label="Claimant Last Name"
                  error={isNotEmpty(this.state.claimantLastNameError)}
                  helperText={this.state.claimantLastNameError}
                  value={this.state.claimantLastName}
                  onBlur={(event) => this.handleInputChange(event, true)}
                  onChange={this.handleInputChange}
                  inputProps={{ maxLength: 40 }}
                  fullWidth
                  required
                />
              </Grid>

              {/* Row 4 */}
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    error={isNotEmpty(this.state.claimantDateOfBirthError)}
                    helperText={this.state.claimantDateOfBirthError}
                    value={this.state.claimantDateOfBirth}
                    onChange={(date) => {
                      if (date instanceof Date) {
                        date.setHours(8, 0, 0, 0);
                        this.setState({ claimantDateOfBirth: date });
                      } else {
                        this.setState({
                          claimantDateOfBirth: null,
                        });
                      }
                    }}
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="Claimant Date of Birth"
                    fullWidth
                    disableFuture
                    placeholder="MM/DD/YYYY"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    required
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    error={isNotEmpty(this.state.approximateServiceDateError)}
                    helperText={this.state.approximateServiceDateError}
                    value={this.state.approximateServiceDate}
                    onChange={(date) => {
                      if (date instanceof Date) {
                        date.setHours(8, 0, 0, 0);
                        this.setState({ approximateServiceDate: date });
                      } else {
                        this.setState({
                          approximateServiceDate: null,
                        });
                      }
                    }}
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="Approximate Service Date (± 7 Days)"
                    fullWidth
                    disableFuture
                    placeholder="MM/DD/YYYY"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    required
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </div>
        </ContentToggle>

        <ContentToggle
          style={{ fontSize: "1rem", fontWeight: "bold" }}
          moreLabel="Search By Bill ID"
          lessLabel="Search By Bill ID"
          labelClass={styles.labelClass}
          onExpand={() => this.handleContentToggleExpanded("billNumber")}
          expanded={this.state.searchBy === "billNumber"}
          disabled={this.state.searchBy === "billNumber"}
          onCollapse={() => console.log("collapsed")}
        >
          <div className="billSearchHelp">
            <Grid container spacing={3}>
              {/* Row 1 */}
              <Grid item xs={12}>
                <MultiSelectCheckbox
                  name="selectedFtins"
                  itemList={this.state.tinList}
                  label="Select Tax ID (5 Maximum)"
                  onBlur={(event) => this.handleInputChange(event, true)}
                  onChange={this.handleInputChange}
                  maxSelectedCount={5}
                  value={this.state.selectedFtins}
                  error={isNotEmpty(this.state.selectedFtinsError)}
                  helperText={this.state.selectedFtinsError}
                  required
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  name="billControlNumber"
                  label="Bill Control Number"
                  fullWidth
                  required
                  error={!isEmpty(this.state.billControlNumberError)}
                  helperText={this.state.billControlNumberError}
                  disabled={isNotEmpty(this.state.documentId)}
                  value={this.state.billControlNumber}
                  onBlur={(event) => this.handleInputChange(event, true)}
                  onChange={this.handleInputChange}
                  inputProps={{ maxLength: 22 }}
                />
              </Grid>

              <Grid item xs={2} className="align-self-center">
                <p className="theLabel-or">OR</p>
              </Grid>

              <Grid item xs={5}>
                <TextField
                  name="documentId"
                  label="Document ID Number"
                  error={!isEmpty(this.state.documentIdError)}
                  helperText={this.state.documentIdError}
                  disabled={isNotEmpty(this.state.billControlNumber)}
                  value={this.state.documentId}
                  onBlur={(event) => this.handleInputChange(event, true)}
                  onChange={this.handleInputChange}
                  inputProps={{ maxLength: 50 }}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </div>
        </ContentToggle>

        <div className="buttonBar">
          <Button
            disabled={!this.props.isSearchEnabled}
            onClick={this.handleSearchButtonOnClick}
            aria-label="Bill Search: Claimant Information SEARCH Button"
          >
            Search
          </Button>
          <Button
            variant="outlined"
            onClick={this.handleResetButtonOnClick}
            aria-label="Bill Search: Claimant Information RESET Button"
          >
            Reset
          </Button>
        </div>
      </div>
    );
  }
}

export default ClaimantInformationInputComponent;
