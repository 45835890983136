// User Stories: 145382, 146429, 148541

import React, { Component } from 'react';
import { Grid, Button, MenuItem, TextField, FormControl, FormLabel } from "@material-ui/core";
import GlobalMessage from '../../GlobalMessage';
import {
    isEmpty, isNotEmpty, isNotEqual, isAlpha, hasLength, isName,
    isUserName, isPhoneNumber, isAuthenticationCode, isAlphanumericWithSpace,
    isTaxId, isBillControlNumber, isProviderId, getNumericCharacterCount, getUpperCaseCharacterCount, getLowerCaseCharacterCount, containsSpecialCharacter, containsInvalidCharacters
} from '../../../utils/Validator.js';
import { getValue, setInitialFocus, setFocusOnFirstError, toLowerCase } from '../../../utils/Utils.js';
import { formatPhoneNumber } from '../../../utils/Format.js';
import RegistrationService from "../../../Services/RegistrationService";
import {NavLink, Redirect} from "react-router-dom";
import "./Registration.css";
import ConfirmationDialog from "./ConfirmationDialog.js";
import LoadingMsgBar from "../../LoadingMsgBar";
import PasswordRequirements from "../PasswordRequirements";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PasswordRequirementsError from "../PasswordRequirementsError";


window.analytics = { pageName: 'Registration: Confirm Registration' };

class ConfirmRegistration extends Component {
    constructor(props) {
        super(props);

        this.state = this.initializeState();
    }

    // need to pass in all of the form data from prev steps
    initializeState() {
        const initialState = {
            step: '',
            globalErrorMessage: '',
            processingMessage: '',
            authenticationCode: '',
            activationToken: '',
            taxId: '',
            billControlNumber: '',
            providerId: '',
            userName: '',
            firstName: '',
            lastName: '',
            middleInitial: '',
            phoneNumber: '',
            securityQuestionList: [],
            securityQuestion: '',
            securityAnswer: '',
            password: '',
            verifyPassword: '',
            wasSubmitted: false,         
            showConfirmation: false,
            isSubmitEnabled: true,
            isConfirmationEnabled: true,
            showPassword: false,
            showConfirmPassword: false,
            showPwdRequirementsError: false,
            pwdLengthError: false,
            pwdContainsUpperError: false,
            pwdContainsLowerError: false,
            pwdContainsNumberError: false,
            pwdContainsSpclCharacterError: false,
            pwdContainsUserName: false,
            showUserNameCheck: false,
            campaign:''
        };

        return initialState;
    }

    componentDidMount() {
        var urlParams = new URLSearchParams(this.props.location.search);

        const expirationTime = getValue(urlParams.get('expire'), 0);
        const campaign = getValue(urlParams.get('campaign'), '');
        if (this.isLinkExpired(expirationTime)) {
            this.setState({ step: 'LinkExpired' });
        } else {
            this.setState({
                activationToken: urlParams.get('token'),
                step: 'AuthenticationStep', campaign: campaign
            });

          //  this.loadSecurityQuestions();
        }

        setInitialFocus('div.registration');
    }

    isLinkExpired(expirationTime) {
        return expirationTime < Date.now();
    }

   /*  loadSecurityQuestions() {
        const securityQuestionList = [];
        let questionList = null;

        this.showProcessingIndicator(true);

        RegistrationService.findSecurityQuestions().then((response) => {
            this.showProcessingIndicator(false);

            questionList = response.data;

            if (isNotEmpty(questionList)) {
                securityQuestionList.push({
                    value: 'Select One',
                    label: 'Select One'
                });
                this.setState({ securityQuestion: 'Select One' });

                questionList.forEach(question => {
                    securityQuestionList.push({
                        value: question.secQstnId,
                        label: question.secQstn
                    });
                });
            }

            this.setState({ securityQuestionList: securityQuestionList });
        }, (error) => {
            console.log(error);
            this.showProcessingIndicator(false);
            this.addErrorMessage("globalErrorMessage", "Error: There was an error retrieving data. Please try again or contact Customer Service.");
            this.setState({ securityQuestionList: securityQuestionList });
        });
    } */

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    // save any changes the user makes into the state variable so you can
    // get back to the state the user was in
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (isEmpty(name)) {
            console.error(`${target.id} has an empty name attribute. Entered: ${value}`);
        }

        if (name === "userName") {
            this.setState({
                [name]: toLowerCase(value.trim())
            });
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    // messageList = [{field: "fieldName", message: "Error message"}, {field: "fieldName", message: "Error message"}]
    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    handleCancelButtonOnClick = () => {
        this.navigateToLoginPage();
    }

    handleDoneButtonOnClick = () => {
        this.navigateToLoginPage();
    }

    showProcessingIndicator(shouldShow) {
        this.setState({ processingMessage: shouldShow ? `Please wait while we process your request.` : '' });
    }

    navigateToLoginPage() {
        this.props.history.push('/wcpp/login');
    }

    clearErrorMessages() {
        const errorFieldList = [
            'authenticationCodeError',
            'taxIdError',
            'billControlNumberError',
            'providerIdError',
            'missingFieldError',
            'userNameError',
            'firstNameError',
            'lastNameError',
            'middleInitialError',
            'phoneNumberError',
            'securityQuestionError',
            'securityAnswerError',
            'passwordError',
            'verifyPasswordError',
            'globalErrorMessage'
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });
    }


    getFormData(checkGrpExists) {
        return {
            activationToken: this.state.activationToken.trim(),
            authenticationCode: this.state.authenticationCode.trim(),
            ftin: this.state.taxId.trim(),
            billCntrlNum: this.state.billControlNumber.trim(),
            cpdId: this.state.providerId.trim(),
            userName: this.state.userName.trim(),
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            middleName: this.state.middleInitial.trim(),
            phoneNumber: this.state.phoneNumber.replace(/[^\d]/g, ""),
            //userSecurityPreferences: [this.buildSecurityQuestionAnswerResponse()],
            password: this.state.password.trim(),
            failWhenFtinAlreadyRegistered: checkGrpExists,
            campaign: this.state.campaign
        }
    }

    getPwdValidation() {
        let has8To14Chars = true;
        let hasAtLeastOneUpperCaseLetter = true;
        let hasAtLeastOneLowerCaseLetter = true;
        let hasAtLeastOneNumber = true;
        let hasAtLeastOneSpecialCharacter = true;
        let hasInvalidCharacter = false;
        let validSpclCharacter = true;
        //let hasAllowableCharacters = true;
       
       
        this.setState({showPwdRequirementsError: false,
            pwdLengthError: false,
            pwdContainsUpperError: false,
            pwdContainsLowerError: false,
            pwdContainsNumberError: false,
            pwdContainsSpclCharacterError: false,
            pwdContainsUserName: false,
            showUserNameCheck: false
           });
        let password = this.state.password;  

        has8To14Chars = hasLength(password, 8, 20);
        hasAtLeastOneNumber = getNumericCharacterCount(password) > 0;
        hasAtLeastOneUpperCaseLetter = getUpperCaseCharacterCount(password) > 0;
        hasAtLeastOneLowerCaseLetter = getLowerCaseCharacterCount(password) > 0;
       // hasAtLeastOneSpecialCharacter = getSpecialCharacterCount(password) > 0;
        hasAtLeastOneSpecialCharacter =  containsSpecialCharacter(password);
        hasInvalidCharacter = containsInvalidCharacters(password);
        validSpclCharacter = (hasAtLeastOneSpecialCharacter && !hasInvalidCharacter);
       
        return { has8To14Chars: has8To14Chars, hasAtLeastOneUpperCaseLetter: hasAtLeastOneUpperCaseLetter,  hasAtLeastOneLowerCaseLetter: hasAtLeastOneLowerCaseLetter, 
            hasAtLeastOneNumber: hasAtLeastOneNumber, 
            hasAtLeastOneSpecialCharacter: validSpclCharacter
           
        };
    }

    checkPwdErrors(pwdResults){
        let isValid = true;
        if(!pwdResults.has8To14Chars){
            this.setState({pwdLengthError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneUpperCaseLetter){
            this.setState({pwdContainsUpperError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneLowerCaseLetter){
            this.setState({pwdContainsLowerError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneNumber){
            this.setState({pwdContainsNumberError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneSpecialCharacter){
            this.setState({pwdContainsSpclCharacterError: true});
            isValid = false;
        }
      
        return isValid ;
    }

    validatePwdForUserName = () => {
        this.setState({pwdLengthError: false});
        this.setState({pwdContainsUpperError: false});
        this.setState({pwdContainsLowerError: false});
        this.setState({pwdContainsNumberError: false});
        this.setState({pwdContainsSpclCharacterError: false});
        this.setState({pwdContainsUserName:true});
        this.addErrorMessage("globalErrorMessage", 'New Password does not meet requirements. Please try again.');
        this.setState({showUserNameCheck: true, showPwdRequirementsError:true, isSubmitEnabled: true }); 
    }
    

    // Returns  { hasSyntaxErrors: true, syntaxMessageList: [{field: "fieldName", message: "the message"}]}
    // Returns  { hasSyntaxErrors: false, syntaxMessageList: []}
    doSyntaxValidation() {
        const messageList = [];
        let isValid = false;

        if (isEmpty(this.state.authenticationCode)) {
            messageList.push({ field: "authenticationCodeError", message: "Error: Enter Authentication Code" });
        } else if (!isAuthenticationCode(this.state.authenticationCode)) {
            messageList.push({ field: "authenticationCodeError", message: "Error: Authentication Code invalid" });
        }

        if (this.state.taxId.length === 0) {            
            messageList.push({ field: "taxIdError", message: "Error: Enter a Tax ID Number (TIN)" });            
        }
        if (!isTaxId(this.state.taxId) &&  messageList.length === 0) {
            messageList.push({ field: "taxIdError", message: "Error: Enter a valid Tax ID Number (TIN)" });
        }

        if (isEmpty(this.state.billControlNumber) && isEmpty(this.state.providerId)) {
            if (messageList.length === 0) {
                this.setState({ errCompSelector: '#billControlNumber' });
            }

            messageList.push({ field: "missingFieldError", message: "Error: Enter a Bill Control Number or Provider ID Number" });
        } else if (isNotEmpty(this.state.billControlNumber) && (!isBillControlNumber(this.state.billControlNumber))) {
          //  messageList.push({ field: "billControlNumberError", message: "Error: Enter a valid Bill Control Number" });
        } else if (isNotEmpty(this.state.providerId) && (!isProviderId(this.state.providerId.trim()))) {
            messageList.push({ field: "providerIdError", message: "Error: Enter a valid Provider ID Number" });
        }

        if (!isUserName(this.state.userName)) {
            messageList.push({ field: "userNameError", message: "Error: Username must be 6 to 14 letters and/or numbers" });
        }

        if (!isName(this.state.firstName) || !hasLength(this.state.firstName, 1, 40)) {
            messageList.push({ field: "firstNameError", message: "Error: Enter valid First Name" });
        }

        if (!isName(this.state.lastName) || !hasLength(this.state.lastName, 1, 40)) {
            messageList.push({ field: "lastNameError", message: "Error: Enter valid Last Name" });
        }

        if (isNotEmpty(this.state.middleInitial) && !isAlpha(this.state.middleInitial)) {
            messageList.push({ field: "middleInitialError", message: "Error: Enter Middle Initial" });
        }

        if (!isPhoneNumber(this.state.phoneNumber)) {
            messageList.push({ field: "phoneNumberError", message: "Error: Enter a valid Phone Number" });
        }

       /*  if (isEmpty(this.state.securityQuestion) || (this.state.securityQuestion === 'Select One')) {
            messageList.push({ field: "securityQuestionError", message: "Error: Select a Secret Question" });
        }

        if (!isAlphanumericWithSpace(this.state.securityAnswer) || !hasLength(this.state.securityAnswer, 1, 120)) {
            messageList.push({ field: "securityAnswerError", message: "Error: Enter Secret Answer" });
        }  */

        if (isEmpty(this.state.password)) {
            messageList.push({ field: "passwordError", message: "Error: Enter New Password" });
        }else if (isNotEqual(this.state.password, this.state.verifyPassword)) {
            messageList.push({ field: "passwordError", message: "Error: Passwords do not match" });
        }

        if (isEmpty(this.state.verifyPassword)) {
            messageList.push({ field: "verifyPasswordError", message: "Error: Confirm New Password" });
        }

        if (messageList.length === 0) {
            isValid = true;
        }

        return { isValid: isValid, messageList: messageList };
    }

    validate = () => {
        this.setState({isSubmitEnabled: false});
        const syntaxResults = this.doSyntaxValidation();
        let pwdResults  = this.getPwdValidation();
        const validPwd = this.checkPwdErrors(pwdResults);
        if(syntaxResults && !validPwd){
            this.setState({showPwdRequirementsError:true, isSubmitEnabled: true });
            this.addErrorMessage("globalErrorMessage", 'New Password does not meet requirements. Please try again.');
            this.addErrorMessage("passwordError", "Error: Password does not meet the Password Requirements");
        }

        if (!syntaxResults.isValid) {
            this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
            this.showMessages(syntaxResults.messageList);
            this.setState({isSubmitEnabled: true});
        }

        return syntaxResults.isValid & validPwd;
        //return syntaxResults.isValid ;
    }

    componentDidUpdate() {
        if (this.state.wasSubmitted) {
            setFocusOnFirstError(this.state.errCompSelector);

            this.setState({
                wasSubmitted: false,
                errCompSelector: null
            });
        }
    }

    completeInvitationRequest(checkGrpExists) {
        let formData = null;

        this.clearErrorMessages();

        // Used in setting focus on first error
        this.setState({ wasSubmitted: true });

        if (this.validate()) {
            this.showProcessingIndicator(true);

            formData = this.getFormData(checkGrpExists);

            RegistrationService.submitAuthenticationForm(formData).then(() => {
                this.showProcessingIndicator(false);
                this.setState({ step: "ThankYou" });

            }, (error) => {
                let message = "";

                if (error.response !== undefined) {
                    message = getValue(error.response.data.message, '');
                }

                this.showProcessingIndicator(false);
                this.dealWithBusinessErrors(message);
                this.setState({isSubmitEnabled: true});
            });
        }
    }

    dealWithBusinessErrors(errorMessage) {
        if (errorMessage.startsWith("INVALID_USERNAME")) {
            this.addErrorMessage("userNameError", "Error: The Username is associated to another account");
            this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
        } else if (errorMessage.startsWith("INVALID_TOKEN")) {
            this.addErrorMessage("authenticationCodeError", "Error: Authentication Code invalid");
            this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
        } else if (errorMessage.startsWith("INVALID_FTIN_BCN")) {
            this.setState({ errCompSelector: '#billControlNumber' });
            this.addErrorMessage("missingFieldError", "");
            this.addErrorMessage("globalErrorMessage", 'Error: The Tax ID Number (TIN) and Bill Control Number combination you have entered is not on file. Please try a different combination or contact Customer Service.');
        }else if (errorMessage.startsWith("VALID_FTIN_INVALID_BCN")) {
            this.setState({ errCompSelector: '#billControlNumber' });
            this.addErrorMessage("missingFieldError", "");
            this.addErrorMessage("globalErrorMessage", 'Error: We have verified the Tax ID Number (TIN), but the Tax ID Number (TIN) and Bill Control Number combination you have entered is not on file. Please try a different combination or contact an Account Administrator who has access to the correct information.');
        }else if (errorMessage.startsWith("INVALID_FTIN_CPDID")) {
            this.setState({ errCompSelector: '#billControlNumber' });
            this.addErrorMessage("missingFieldError", "");
            this.addErrorMessage("globalErrorMessage", 'Error: The Tax ID Number (TIN) and Provider ID Number combination you have entered is not on file. Please try a different combination or contact Customer Service.');
        }else if (errorMessage.startsWith("VALID_FTIN_INVALID_CPDID")) {
            this.setState({ errCompSelector: '#billControlNumber' });
            this.addErrorMessage("missingFieldError", "");
            this.addErrorMessage("globalErrorMessage", 'Error: We have verified the Tax ID Number (TIN), but the Tax ID Number (TIN) and Provider ID Number combination you have entered is not on file. Please try a different combination or contact an Account Administrator who has access to the correct information.');
        }else if (errorMessage.startsWith("INVALID_PW")) {
            this.addErrorMessage("passwordError", "Error: Password does not meet the Password Requirements");
            this.validatePwdForUserName();
            //this.addErrorMessage("passwordError", "Error: Password does not meet the Password Requirements");
            //this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
        }else if (errorMessage.startsWith("FTIN_RESTRICTED_FOR_REGISTRATION")) {
           //TODO
           this.setState({ errCompSelector: '#taxId' });
           this.addErrorMessage("missingFieldError", "");
           this.addErrorMessage("globalErrorMessage", 'Error: Registration is not allowed for this TIN.  Please select a different TIN and resubmit.');
        }else if (errorMessage.startsWith("FTIN_ALREADY_REGISTERED")) {
            this.showConfirmationPopup();
        } else {
            this.addErrorMessage("globalErrorMessage", "Error: An error has occurred. Please try again or contact Customer Service.");
        }
    }

    handleSubmitButtonOnClick = () => {
        this.completeInvitationRequest(true);
    }

    handleExitedPhoneNumberField = () => {
        const formattedPhoneNumber = formatPhoneNumber(this.state.phoneNumber);

        if (formattedPhoneNumber !== this.state.phoneNumber) {
            this.setState({ phoneNumber: formattedPhoneNumber });
        }
    }

    showConfirmationPopup = () => {
        this.setState({ showConfirmation: true });
    }

    hideConfirmationPopup = () => {
        this.setState({
            showConfirmation: false,
        });
    }

    handleConfirmationYesOnClick = () => {
        this.setState({
            failWhenFtinAlreadyRegistered: false,
            isConfirmationEnabled: false
        });
        this.hideConfirmationPopup();
        this.completeInvitationRequest(false);
    }

    handleConfirmationNoOnClick = () => {       
        this.hideConfirmationPopup();
    }

    handleClickShowPassword= (event) => {
        console.log('password');
        if(this.state.showPassword){
            this.setState({showPassword : false})
        }
        if(!this.state.showPassword){
            this.setState({showPassword : true})
        }
      };

      handleClickShowConfirmPassword= (event) => {
        console.log('password');
        if(this.state.showConfirmPassword){
            this.setState({showConfirmPassword : false})
        }
        if(!this.state.showConfirmPassword){
            this.setState({showConfirmPassword : true})
        }
      };
      
      handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    render() {
        if (this.state.step === "LinkExpired") {
            return this.renderLinkExpired();
        } else if (this.state.step === "AuthenticationStep") {
            return this.renderAuthenticationForm();
        } else if (this.state.step === "ThankYou") {
            return this.renderThankYou();

        } else {
            return <p>OOPS!</p>
        }
    }

    renderThankYou() {
        return(
            <Redirect to={`/wcpp/registrationComplete?campaign=${this.state.campaign}`}/>
                );
     }

    renderAuthenticationForm() {
        return (
            <div className="registration">
                <ConfirmationDialog title="The TIN has already been registered."
                    open={this.state.showConfirmation}
                    yesLabel="Complete Registration" noLabel="Cancel"
                    disabled={!this.state.isConfirmationEnabled}
                    handleYes={this.handleConfirmationYesOnClick} handleNo={this.handleConfirmationNoOnClick}>
                    <p>One or more groups are using this TIN. If you want to be added to an existing group, please contact the Account Administrator. If you want to create a new group, please complete registration.</p>
                </ConfirmationDialog>

                <h1 className="App-page-label">Coventry Provider Portal Registration</h1>

                <LoadingMsgBar loadMsg={this.state.processingMessage} />

                <GlobalMessage message={this.state.globalErrorMessage} />
                <p>If you have a Provider Portal Account Administrator for your group, ask them to create an account for you. Otherwise, please fill in the information below to complete registration.</p>
                <div className='App-error-font App-error-color App-note-Field'>Note: Fields marked with an asterisk(*) are required.</div>

                <Grid container direction="column">
                    <Grid item xs={12} md={5}>
                        <TextField id="authenticationCode" name="authenticationCode" label="Authentication Code"
                            error={isNotEmpty(this.state.authenticationCodeError)} helperText={this.state.authenticationCodeError}
                            value={this.state.authenticationCode}
                            onChange={this.handleInputChange}
                            inputProps={{ maxLength: 23 }}
                            fullWidth
                            autoFocus
                            required
                        />
                    </Grid>
                </Grid>

                <FormControl component="fieldset">
                    <FormLabel component="legend" className="legend">Provider Information</FormLabel>
                    <Grid container direction="row" justify="flex-start" alignItems="center">

                        <Grid item xs={12} md={5}>
                            <TextField id="taxId" name="taxId" label="Tax ID Number (TIN)"
                                error={!isEmpty(this.state.taxIdError)} helperText={this.state.taxIdError}
                                value={this.state.taxId}
                                onChange={this.handleInputChange}
                                inputProps={{ maxLength: 9 }}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>

                    <p className='theLabel-and'>AND</p>

                    <Grid container direction="row" alignItems="flex-start">
                        <Grid item xs={12} md={5}>
                            <TextField id="billControlNumber" name="billControlNumber" label="Bill Control Number"
                                error={!isEmpty(this.state.billControlNumberError)} helperText={this.state.billControlNumberError}
                                disabled={isNotEmpty(this.state.providerId)}
                                value={this.state.billControlNumber}
                                onChange={this.handleInputChange}
                                inputProps={{ maxLength: 22 }}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={1} className="align-self-center">
                            <p className='theLabel-or'>OR</p>
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <TextField id="providerId" name="providerId" label="Provider ID Number"
                                error={!isEmpty(this.state.providerIdError)} helperText={this.state.providerIdError}
                                disabled={isNotEmpty(this.state.billControlNumber)}
                                value={this.state.providerId}
                                onChange={this.handleInputChange}
                                inputProps={{ maxLength: 8 }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <span hidden={isEmpty(this.state.missingFieldError)} className="errorMessage">{this.state.missingFieldError}</span>
                </FormControl>

                <Grid container direction="column">
                    <FormControl component="fieldset">
                        <FormLabel component="legend" className="legend">Your Information</FormLabel>

                        <Grid item xs={12} md={5}>
                            <TextField id="firstName" name="firstName" label="First Name"
                                error={isNotEmpty(this.state.firstNameError)} helperText={this.state.firstNameError}
                                value={this.state.firstName}
                                onChange={this.handleInputChange}
                                inputProps={{ maxLength: 40 }}
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <TextField id="lastName" name="lastName" label="Last Name"
                                error={isNotEmpty(this.state.lastNameError)} helperText={this.state.lastNameError}
                                value={this.state.lastName}
                                onChange={this.handleInputChange}
                                inputProps={{ maxLength: 40 }}
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField id="middleInitial" name="middleInitial" label="Middle Initial"
                                error={isNotEmpty(this.state.middleInitialError)} helperText={this.state.middleInitialError}
                                value={this.state.middleInitial}
                                onChange={this.handleInputChange}
                                inputProps={{ maxLength: 1 }}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <TextField id="phoneNumber" name="phoneNumber" label="Phone Number"
                                error={isNotEmpty(this.state.phoneNumberError)} helperText={this.state.phoneNumberError}
                                value={this.state.phoneNumber}
                                onChange={this.handleInputChange}
                                onBlur={this.handleExitedPhoneNumberField}
                                inputProps={{ maxLength: 14 }}
                                fullWidth
                                required
                            />
                        </Grid>
                    </FormControl>

                    <FormControl component="fieldset">
                        <FormLabel component="legend" className="legend">Account Security</FormLabel>
                        <Grid container>
                            <Grid item xs={12} md={5}>
                                <Grid item xs={12}>
                                    <TextField id="userName" name="userName" label="Username"
                                        error={isNotEmpty(this.state.userNameError)} helperText={this.state.userNameError}
                                        value={this.state.userName}
                                        onChange={this.handleInputChange}
                                        inputProps={{ maxLength: 14 }}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={5}>
                                <Grid item xs={12}>
                                    <TextField id="password" name="password" label="New Password"
                                        type={this.state.showPassword ? "text" : "password"}
                                        error={isNotEmpty(this.state.passwordError)} helperText={this.state.passwordError}
                                        value={this.state.password}
                                        onChange={this.handleInputChange}
                                        fullWidth
                                        required
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton disableRipple={true} size="small"
                                                onClick={this.handleClickShowPassword}
                                               >
                                                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            )}}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField id="verifyPassword" name="verifyPassword" label="Confirm New Password"
                                        type={this.state.showConfirmPassword ? "text" : "password"}
                                        error={isNotEmpty(this.state.verifyPasswordError)} helperText={this.state.verifyPasswordError}
                                        value={this.state.verifyPassword}
                                        onChange={this.handleInputChange}
                                        fullWidth
                                        required
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton disableRipple={true} size="small"
                                                onClick={this.handleClickShowConfirmPassword}
                                               >
                                                  {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            )}}
                                    />
                                </Grid>

                              {/*   <Grid item xs={12}>
                                    <TextField name="securityQuestion" id="securityQuestion" label="Secret Question"
                                        error={isNotEmpty(this.state.securityQuestionError)} helperText={this.state.securityQuestionError}
                                        select
                                        value={this.state.securityQuestion}
                                        onChange={this.handleInputChange}
                                        fullWidth
                                        required
                                    >
                                        {this.state.securityQuestionList.map(question => (
                                            <MenuItem key={question.value} value={question.value}>
                                                {question.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField id="securityAnswer" name="securityAnswer" label="Secret Answer"
                                        error={isNotEmpty(this.state.securityAnswerError)} helperText={this.state.securityAnswerError}
                                        value={this.state.securityAnswer}
                                        onChange={this.handleInputChange}
                                        inputProps={{ maxLength: 120 }}
                                        fullWidth
                                        required
                                    />
                                </Grid> */}

                            
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                {(!this.state.showPwdRequirementsError) ? 
                                    <PasswordRequirements/> 
                                :
                                     <PasswordRequirementsError pwdLengthError={this.state.pwdLengthError} pwdContainsUpperError={this.state.pwdContainsUpperError}
                                        pwdContainsLowerError={this.state.pwdContainsLowerError} pwdContainsNumberError={this.state.pwdContainsNumberError}
                                        pwdContainsSpclCharacterError = {this.state.pwdContainsSpclCharacterError} showUserNameCheck = {this.state.showUserNameCheck} 
                                        pwdContainsUserName={this.state.pwdContainsUserName}
                                       />
                                 } 
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Grid>

                <div className="buttonBar">
                    <Button disabled={!this.state.isSubmitEnabled} onClick={this.handleSubmitButtonOnClick} aria-label='Coventry Provider Portal Registration: SUBMIT Button'>Submit</Button>
                    <Button variant="outlined" onClick={this.handleCancelButtonOnClick} aria-label='Coventry Provider Portal Registration: CANCEL Button'>Cancel</Button>
                </div>
            </div>
        );
    }

    renderLinkExpired() {
        return (
            <div className="registration">
                <p>Your activation link has expired, please <NavLink autoFocus className='Blue-font' to='/wcpp/registration'>restart registration</NavLink>.</p>
            </div>
        );
    }
}

export default ConfirmRegistration;
