import React, { useReducer} from 'react';
import {compareIgnoreCase, isEmpty} from "../../../utils/Utils";
import {isNotEmpty} from "../../../utils/Validator";
import LoadingMsgBar from "../../LoadingMsgBar";
import {Grid} from "@material-ui/core";
import DemographicSearchTable from "./DemographicSearchTable";
import "bootstrap/dist/css/bootstrap.min.css";

import useGetTaxIds from "../../../hooks/use-getTaxIds";
import Physician from "./Physician/Physician";
import Facility from "./Facility/Facility";
import ProviderService from "../../../Services/ProviderService";
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";


const reducer = (state, action) => {
    switch (action.type) {
        case "PROVIDERS_RTRN":
            return {...state, providers: action.providers, providersFiltered: action.providersFiltered}
        case "BUSY_MSG":
            return {...state,isBusyMsg:action.isBusyMsg}
        case "SEARCH_DONE":
            return {...state,searchDone:action.searchDone}
        case "TAXID":
            return {...state,taxIdsOptions:action.taxIdsOptions}
        case "PROVIDER_PHYS_RTRN":
            return {...state,physician:action.physician}
        case "FILTERED":
            return {...state,providersFiltered: action.providersFiltered}
        case "SELECTED":
            return {...state, selectedPrvId: action.selectedPrvId, selectedType: action.selectedType,
                selectedTin: action.selectedTin}
        case "TAXID_SELECTED":

            return {...state,selectedTaxId: action.selectedTaxId}
        default:
            return state
    }
}
function DemographicSearch() {
    const [state, dispatch ] =useReducer(reducer,{
        selectedTaxId:'',selectedPrvId:'',providers:[],searchDone:false,
        providersFiltered:[],isBusyMsg:'', physician:null, selectedType:'', selectedTin:'',
    });

    const taxIdsOptions = useGetTaxIds();

    const getProviders = (taxId) => {

        dispatch({type:"BUSY_MSG",isBusyMsg: 'Loading Providers with selected Tax ID...'});
        dispatch({type:"PROVIDERS_RTRN", providersFiltered:[], providers: []});
        ProviderService.getProviders(taxId)
            .then((response) => {

                dispatch({type:"BUSY_MSG",isBusyMsg: ''});
                if (isNotEmpty(response.data)) {

                    let data = response.data;

                    data.sort((a, b) => compareIgnoreCase(a.prvName, b.prvName));
                    dispatch({type:"PROVIDERS_RTRN", providersFiltered:data, providers: data})

                } else {
                    dispatch({type:"SEARCH_DONE",searchDone: true});

                }

            }).catch((error) => {
            console.log('Error GETTING THE PROVIDERS' + error);
            dispatch({type:"BUSY_MSG",isBusyMsg: ''});
        })
    };


   const setFilter = (filtered) => {
           if(filtered && filtered.length >= 0)
               dispatch({type:"FILTERED",providersFiltered: filtered});

    };

    const setSelectedPrvId = (prvId, type, taxId) => {
            dispatch({ type: "SELECTED", selectedPrvId: prvId, selectedType: type, selectedTin:taxId});
    }

    const getTable = () => {
        if (state.providers.length > 0) {
            return (
                <DemographicSearchTable clients={state.providersFiltered} setFilter={setFilter}
                             setPrvId={setSelectedPrvId} unfilteredUsers={state.providers}
                                        cnt={state.providers.length}/>
            );
        } else if(state.searchDone=== true && isEmpty(state.isBusyMsg)){
            return (
                <div className='App-error-font' style={{paddingTop: '3rem', paddingBottom:'5rem'}}>No Providers found for the selected Tax ID
                    </div>
            );
        } else {
            return (
                <div style={{paddingTop: '3rem', paddingBottom:'5rem'}}>Select a Tax ID to Search for Providers...
                </div>
            );
        }
    };

    const handleTaxIdSelected = (value ) => {

        if(!value) {
            dispatch({ type: "TAXID_SELECTED", selectedTaxId: []});
            dispatch({type:"PROVIDERS_RTRN", providersFiltered:[], providers: []});
        } else {
            dispatch({ type: "TAXID_SELECTED", selectedTaxId: value});
            getProviders(value);
        }


    };


    const handleBackToSearch = () => {
        dispatch({ type: "SELECTED", selectedPrvId: ''});
    }

    const renderProviderSearch = () => {
        return(
            <div className="App-content providerSearchInputComponent">
                <h1 className="App-page-label" style={{margin: 0}}>Demographic Search</h1>

                <LoadingMsgBar loadMsg={state.isBusyMsg}/>

                <Grid container direction="column">
                    <Grid item xs={12} md={5}>
                        <Autocomplete
                            value={!state.selectedTaxId? taxIdsOptions[0]: state.selectedTaxId} options={taxIdsOptions}

                            onChange={(event, newValue) => {
                                handleTaxIdSelected(newValue);
                            }}

                            id="taxId"
                            renderInput={(params) => <TextField {...params} label="Select Tax ID" variant="outlined" />}

                        />

                    </Grid>
                </Grid>
                <div className='ClntLstResponse'>
                    {getTable()}
                </div>


            </div>

        );
    }

    //return isNotEmpty(state.selectedPrvId) ? <Physician back={handleBackToSearch} selectedPrvId={state.selectedPrvId} selectedTin={state.selectedTaxId.value}/>: renderProviderSearch();

    return isNotEmpty(state.selectedPrvId) ? 
   

    <>
										{ (state.selectedType === 'P') ?
											<>											
												 <Physician back={handleBackToSearch} selectedPrvId={state.selectedPrvId} selectedTin={state.selectedTin}/>
											</>
											: 
											<>
												<Facility back={handleBackToSearch} selectedPrvId={state.selectedPrvId} selectedTin={state.selectedTin}/>
											</>
										}
										</>


 
    
        : renderProviderSearch();

}
export default DemographicSearch;