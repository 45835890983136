import React, { Component } from 'react';
import { Grid,  Button, TextField, MenuItem } from "@material-ui/core";
import {
    isEmpty, isNotEmpty, isNotEqual, hasLength,
    isAlphanumericWithSpace,
    isUserName,  isAuthenticationCode, getNumericCharacterCount, getUpperCaseCharacterCount, getLowerCaseCharacterCount, containsSpecialCharacter, containsInvalidCharacters
    
} from '../../utils/Validator.js';
import { getValue, toLowerCase } from '../../utils/Utils.js';
import { formatPhoneNumber } from '../../utils/Format.js';
import RegistrationService from "../../Services/RegistrationService";
import { Redirect} from "react-router-dom";
import "./Registration/Registration.css";
import LoadingMsgBar from "../LoadingMsgBar";
import GlobalMessage from "../GlobalMessage";
import PasswordRequirements from "./PasswordRequirements";
import PasswordRequirementsError from "./PasswordRequirementsError";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

window.analytics ={pageName:'Manage Users: Add User: Confirm Add User'};
class ConfirmAddUser extends Component {

    constructor(props) {
        super(props);

        this.state = this.initializeState();
    }

    // need to pass in all of the form data from prev steps
    initializeState() {
        let initialState = {
            step: '',
            globalErrorMessage: '',
            processingMessage: '',
            authenticationCode: '',
            activationToken: '',
            userName: '',
            securityQuestionList: [],
            securityQuestion: '',
            securityAnswer: '',
            emailAddress: '',
            password: '',
            verifyPassword: '',
            wasSubmitted: false,
            isSubmitEnabled: true,
            showPassword: false,
            showConfirmPassword: false,
            showPwdRequirementsError: false,
            pwdLengthError: false,
            pwdContainsUpperError: false,
            pwdContainsLowerError: false,
            pwdContainsNumberError: false,
            pwdContainsSpclCharacterError: false,
            pwdContainsUserName: false,
            showUserNameCheck: false
            
        };

        return initialState;
    }

    componentDidMount() {
        var urlParams = new URLSearchParams(this.props.location.search);

        let expirationTime = getValue(urlParams.get('expire'), 0);

        if (this.isLinkExpired(expirationTime)) {
            this.setState({ step: 'LinkExpired' });
        } else {
            this.setState({
                activationToken: urlParams.get('token'),
                step: 'AuthenticationStep'
            });

            //this.loadSecurityQuestions();
        }
    }

    isLinkExpired(expirationTime) {
        return expirationTime < Date.now();
    }

    loadSecurityQuestions() {
        const securityQuestionList = [];
        let questionList = null;

        this.showProcessingIndicator(true);

        RegistrationService.findSecurityQuestions().then((response) => {
            this.showProcessingIndicator(false);

            questionList = response.data;

            if (isNotEmpty(questionList)) {
                securityQuestionList.push({
                    value: 'Select One',
                    label: 'Select One'
                });
                this.setState({ securityQuestion: 'Select One' });

                questionList.forEach(question => {
                    securityQuestionList.push({
                        value: question.secQstnId,
                        label: question.secQstn
                    });
                });
            }

            this.setState({ securityQuestionList: securityQuestionList });
        }, (error) => {
            this.showProcessingIndicator(false);
            this.addErrorMessage("globalErrorMessage", "Error: There was an error retrieving data. Please try again or contact Customer Service.");
            this.setState({ securityQuestionList: securityQuestionList });
        });
    }

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }


    // save any changes the user makes into the state variable so you can
    // get back to the state the user was in
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (isEmpty(name)) {
            console.error(`${target.id} has an empty name attribute. Entered: ${value}`);
        }

        if (name === "userName") {
            this.setState({
                [name]: toLowerCase(value.trim())
            });
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    // messageList = [{field: "fieldName", message: "Error message"}, {field: "fieldName", message: "Error message"}]
    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    handleCancelButtonOnClick = () => {
        this.navigateToLoginPage();
    }

    handleDoneButtonOnClick = () => {
        this.navigateToLoginPage();
    }

    showProcessingIndicator(shouldShow) {
        this.setState({ processingMessage: shouldShow === true ? `Please wait while we process your request.` :'' });
    }

    navigateToLoginPage() {
        this.props.history.push('/wcpp/login');
    }

    clearErrorMessages() {
        const errorFieldList = [
            'authenticationCodeError',
            'missingFieldError',
            'userNameError',
            'securityQuestionError',
            'securityAnswerError',
            'emailAddressError',
            'passwordError',
            'verifyPasswordError',
            'globalErrorMessage'
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });
    }

    
    getFormData() {
        return {
            token: this.state.activationToken.trim(),
            authCode: this.state.authenticationCode.trim(),
            password: this.state.password.trim(),
            userDetailsInfo : this.buildUserDetailsInfo()
        }
    } 

    buildUserDetailsInfo() {
        const userDetailsInfo = {};    
        userDetailsInfo.userNm = this.state.userName.trim();
        //userDetailsInfo.userSecurityPreferences = [this.buildSecurityQuestionAnswerResponse()];
        return userDetailsInfo;
    }

    getPwdValidation() {
        let has8To14Chars = true;
        let hasAtLeastOneUpperCaseLetter = true;
        let hasAtLeastOneLowerCaseLetter = true;
        let hasAtLeastOneNumber = true;
        let hasAtLeastOneSpecialCharacter = true;
        let hasInvalidCharacter = false;
        let validSpclCharacter = true;
       
       
        this.setState({showPwdRequirementsError: false,
            pwdLengthError: false,
            pwdContainsUpperError: false,
            pwdContainsLowerError: false,
            pwdContainsNumberError: false,
            pwdContainsSpclCharacterError: false,
            pwdContainsUserName: false,
            showUserNameCheck: false
            });
        let password = this.state.password;  

        has8To14Chars = hasLength(password, 8, 20);
        hasAtLeastOneNumber = getNumericCharacterCount(password) > 0;
        hasAtLeastOneUpperCaseLetter = getUpperCaseCharacterCount(password) > 0;
        hasAtLeastOneLowerCaseLetter = getLowerCaseCharacterCount(password) > 0;
        //hasAtLeastOneSpecialCharacter = getSpecialCharacterCount(password) > 0;
        hasAtLeastOneSpecialCharacter =  containsSpecialCharacter(password);
        hasInvalidCharacter = containsInvalidCharacters(password);
        validSpclCharacter = (hasAtLeastOneSpecialCharacter && !hasInvalidCharacter);
       
        return { has8To14Chars: has8To14Chars, hasAtLeastOneUpperCaseLetter: hasAtLeastOneUpperCaseLetter,  hasAtLeastOneLowerCaseLetter: hasAtLeastOneLowerCaseLetter, 
            hasAtLeastOneNumber: hasAtLeastOneNumber, 
            hasAtLeastOneSpecialCharacter: validSpclCharacter  
            
        };
    }

    checkPwdErrors(pwdResults){
        let isValid = true;
        if(!pwdResults.has8To14Chars){
            this.setState({pwdLengthError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneUpperCaseLetter){
            this.setState({pwdContainsUpperError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneLowerCaseLetter){
            this.setState({pwdContainsLowerError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneNumber){
            this.setState({pwdContainsNumberError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneSpecialCharacter){
            this.setState({pwdContainsSpclCharacterError: true});
            isValid = false;
        }
        
        
        return isValid   ;
    }
    
    doSyntaxValidation() {
        const messageList = [];
        let isValid = false;

        if (isEmpty(this.state.authenticationCode)) {
            messageList.push({ field: "authenticationCodeError", message: "Error: Enter Authentication Code" });
        }else if (!isAuthenticationCode(this.state.authenticationCode)) {
            messageList.push({ field: "authenticationCodeError", message: "Error: Authentication Code invalid" });
        }

        if (!isUserName(this.state.userName)) {
            messageList.push({ field: "userNameError", message: "Error: Username must be 6 to 14 letters and/or numbers" });
        }

       /*  if (isEmpty(this.state.securityQuestion) || (this.state.securityQuestion === 'Select One')) {
            messageList.push({ field: "securityQuestionError", message: "Error: Select a Secret Question" });
        }

        if (!isAlphanumericWithSpace(this.state.securityAnswer) || !hasLength(this.state.securityAnswer, 1, 120)) {
            messageList.push({ field: "securityAnswerError", message: "Error: Enter Secret Answer" });
        } */

        if (isEmpty(this.state.password)) {
            messageList.push({ field: "passwordError", message: "Error: Enter New Password" });
        }else if (isNotEqual(this.state.password, this.state.verifyPassword)) {
            messageList.push({ field: "passwordError", message: "Error: Passwords do not match" });
        }

        if (isEmpty(this.state.verifyPassword)) {
            messageList.push({ field: "verifyPasswordError", message: "Error: Confirm New Password" });
        }

        if (messageList.length === 0) {
            isValid = true;
        }

        return { isValid: isValid, messageList: messageList };
    }


    validate = () => {
        this.setState({isSubmitEnabled: false});
        const syntaxResults = this.doSyntaxValidation();
        let pwdResults  = this.getPwdValidation();
        const validPwd = this.checkPwdErrors(pwdResults);
        if(syntaxResults && !validPwd){
            this.setState({showPwdRequirementsError:true });
            this.setState({errorMsg: `New Password does not meet requirements. Please try again. `});
            this.addErrorMessage("passwordError", "Error: Password does not meet the Password Requirements");
            this.setState({valid: false});
            this.setState({isSubmitEnabled: true});
        }

        if (!syntaxResults.isValid) {
            this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
            this.showMessages(syntaxResults.messageList);
            this.setState({isSubmitEnabled: true});
        }

        return (syntaxResults.isValid & validPwd);
        //return (syntaxResults.isValid);
    }

    componentDidUpdate() {
  
    }

    completeInvitationRequest() {
        let formData = null;

        this.clearErrorMessages();

        // Used in setting focus on first error
        this.setState({ wasSubmitted: true });

        if (this.validate() ) {
            this.showProcessingIndicator(true);

            formData = this.getFormData();

            RegistrationService.completeInternalUserRegistration(formData).then((response) => {
                this.showProcessingIndicator(false);
                this.setState({ step: "ThankYou" });
            }, (error) => {
                let message = "";

                if (error.response !== undefined) {
                    message = getValue(error.response.data.message, '');
                }

                this.showProcessingIndicator(false);
                this.dealWithBusinessErrors(message);
                this.setState({isSubmitEnabled: true});
            });
        }
    }

    validatePwdForUserName = () => {
        this.setState({pwdLengthError: false});
        this.setState({pwdContainsUpperError: false});
        this.setState({pwdContainsLowerError: false});
        this.setState({pwdContainsNumberError: false});
        this.setState({pwdContainsSpclCharacterError: false});
        this.setState({pwdContainsUserName:true});
        this.addErrorMessage("globalErrorMessage", 'New Password does not meet requirements. Please try again.');
        this.setState({showUserNameCheck: true, showPwdRequirementsError:true, isSubmitEnabled: true }); 
    }

    dealWithBusinessErrors(errorMessage) {
        if (errorMessage.startsWith("INVALID_USERNAME")) {
            this.addErrorMessage("userNameError", "Error: The Username is associated to another account");
            this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
        } else if (errorMessage.startsWith("INVALID_TOKEN") ||
                errorMessage.startsWith("INVALID_REGISTRATION_ACTIVATION_TOKEN") ) {
            this.addErrorMessage("authenticationCodeError", "Error: Authentication Code invalid");
            this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
        } else if (errorMessage.startsWith("INVALID_PW")) {
            this.addErrorMessage("passwordError", "Error: Password does not meet the Password Requirements");
            this.validatePwdForUserName();
            //this.addErrorMessage("passwordError", "Error: Password does not meet the Password Requirements");
            //this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
        } else {
            this.addErrorMessage("globalErrorMessage", "Error: An error has occurred. Please try again or contact Customer Service.");
        }
    }

    handleSubmitButtonOnClick = () => {
        this.completeInvitationRequest();
    }

    handleExitedPhoneNumberField = () => {
        let formattedPhoneNumber = formatPhoneNumber(this.state.phoneNumber);

        if (formattedPhoneNumber !== this.state.phoneNumber) {
            this.setState({ phoneNumber: formattedPhoneNumber });
        }
    }

    handleClickShowPassword= (event) => {
        console.log('password');
        if(this.state.showPassword){
            this.setState({showPassword : false})
        }
        if(!this.state.showPassword){
            this.setState({showPassword : true})
        }
      };

      handleClickShowConfirmPassword= (event) => {
        console.log('password');
        if(this.state.showConfirmPassword){
            this.setState({showConfirmPassword : false})
        }
        if(!this.state.showConfirmPassword){
            this.setState({showConfirmPassword : true})
        }
      };
      
      handleMouseDownPassword = (event) => {
        event.preventDefault();
      };


    render() {
        if (this.state.step === "LinkExpired") {
            return this.renderLinkExpired();
        } else if (this.state.step === "AuthenticationStep") {
            return this.renderAuthenticationForm();
        } else if (this.state.step === "ThankYou") {
            return this.renderThankYou();
        } else {
            return <p>OOPS!</p>
        }
    }

    showButtons =() =>{
        return(
            <nav>              
            <Button disabled={!this.state.isSubmitEnabled}  onClick={this.handleSubmitButtonOnClick}>Submit</Button>
            <Button variant="outlined" onClick={this.handleCancelButtonOnClick}>Cancel</Button>
        </nav>
        );
    }

    getPwdRequirementsElement =() =>{
        return(
            (!this.state.showPwdRequirementsError) ? 
                <PasswordRequirements/> 
                :
                <PasswordRequirementsError pwdLengthError={this.state.pwdLengthError} pwdContainsUpperError={this.state.pwdContainsUpperError}
                   pwdContainsLowerError={this.state.pwdContainsLowerError} pwdContainsNumberError={this.state.pwdContainsNumberError}
                   pwdContainsSpclCharacterError = {this.state.pwdContainsSpclCharacterError} showUserNameCheck = {this.state.showUserNameCheck} 
                   pwdContainsUserName={this.state.pwdContainsUserName}
                   />
                );
    }

    renderAuthenticationForm() {
        return (

            <div className="registration">

                <h1 className="pageTitle">Coventry Provider Portal Registration</h1>
                <LoadingMsgBar loadMsg={this.state.processingMessage}/>
                <GlobalMessage  message={this.state.globalErrorMessage} />
                <div >
                    <p className='instructions'>Please complete the information below to complete registration.</p>

                    <div className='App-note-Field App-error-font App-error-color'>
                        Note: Fields marked with an asterisk(*) are required.
                    </div>
                    <Grid container direction="column">
                        <Grid item xs={12} md={5}>
                            <TextField id="authenticationCode" name="authenticationCode" label="Authentication Code"
                                error={isNotEmpty(this.state.authenticationCodeError)} helperText={this.state.authenticationCodeError}
                                value={this.state.authenticationCode}
                                onChange={this.handleInputChange}
                                inputProps={{ maxLength: 23 }}
                                fullWidth
                                autoFocus
                                required
                            />
                        </Grid>
                    </Grid>             
                    <Grid container direction="column">                       
                            <Grid container>
                                <Grid item xs={12} md={5}>
                                    <Grid item xs={12}>
                                        <TextField id="userName" name="userName" label="Username"
                                            error={isNotEmpty(this.state.userNameError)} helperText={this.state.userNameError}
                                            value={this.state.userName}
                                            onChange={this.handleInputChange}
                                            inputProps={{ maxLength: 14 }}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={5}>
                                    <Grid item xs={12}>
                                        <TextField id="password" name="password" label="New Password"
                                            type={this.state.showPassword ? "text" : "password"}
                                            error={isNotEmpty(this.state.passwordError)} helperText={this.state.passwordError}
                                            value={this.state.password}
                                            onChange={this.handleInputChange}
                                            fullWidth
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <IconButton disableRipple={true} size="small"
                                                    onClick={this.handleClickShowPassword}
                                                   >
                                                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                  </InputAdornment>
                                                )}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="verifyPassword" name="verifyPassword" label="Confirm New Password"
                                            type={this.state.showConfirmPassword ? "text" : "password"}
                                            error={isNotEmpty(this.state.verifyPasswordError)} helperText={this.state.verifyPasswordError}
                                            value={this.state.verifyPassword}
                                            onChange={this.handleInputChange}
                                            fullWidth
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <IconButton disableRipple={true} size="small"
                                                    onClick={this.handleClickShowConfirmPassword}
                                                   >
                                                      {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                  </InputAdornment>
                                                )}}
                                        />
                                    </Grid>

                                  {/*   <Grid item xs={12}>
                                        <TextField name="securityQuestion" id="securityQuestion" label="Secret Question"
                                            error={isNotEmpty(this.state.securityQuestionError)} helperText={this.state.securityQuestionError}
                                            select
                                            value={this.state.securityQuestion}
                                            onChange={this.handleInputChange}
                                            fullWidth
                                            required
                                        >
                                            {this.state.securityQuestionList.map(question => (
                                                <MenuItem key={question.value} value={question.value}>
                                                    {question.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="securityAnswer" name="securityAnswer" label="Secret Answer"
                                            error={isNotEmpty(this.state.securityAnswerError)} helperText={this.state.securityAnswerError}
                                            value={this.state.securityAnswer}
                                            onChange={this.handleInputChange}
                                            inputProps={{ maxLength: 120 }}
                                            fullWidth
                                            required
                                        />
                                    </Grid> */}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                       {this.getPwdRequirementsElement()}
                                    </Grid>
                                </Grid>
                            </Grid>                    
                    </Grid>
                </div>
                {this.showButtons()}
            </div>
        );
    }

    renderThankYou() {
        return (
            <Redirect to={`/wcpp/registrationComplete?campaign=${this.state.campaign}`}/>
        );
    }

    renderLinkExpired() {
        return (

            <div className="registration">

                <div className="step-main">
                    <p>Your activation link has expired, please contact your Account Administrator to Resend Registration.</p>
                </div>
            </div>
        );
    }
}

export default ConfirmAddUser;
