import React, {Component, Fragment} from 'react'
import AuthenticationService from "../../Services/AuthenticationService";
import './ManageTinsForUser.css'
import Button from '@material-ui/core/Button';
import ManageTinsForUserTable from '../ManageTinsForUserTable'
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

window.analytics ={pageName:'Add User: Manage TINs'};
class AddUserManageTin extends Component {
    constructor(props) {
        super(props);
        this.state = { previousScreen : null, userAdmin:'', loggedInUser :  null,plainPhoneNum :'',
            ftins: [], ftinsFiltered: [], selected: [], errorMsg: '', showDlgMsg: false, ftinSelected:[],
            isCreateUserEnabled: true
        }
    }
    componentDidMount() {
        const prvScreen = this.props.location.state.previousScreen;
        this.setState({previousScreen:prvScreen});
        this.setState({loggedInUser : prvScreen.loggedInUserInfo});
        this.loadList();
        this.setState({isCreateUserEnabled: true});
    }

    loadList = () => {
        console.log( `Mange users get tins for user................`)
        this.setState({userAdmin:AuthenticationService.getLoggedInUserName()});
        AuthenticationService.getFtinsForManageUser( AuthenticationService.getLoggedInUserName())
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ftins: response.data, ftinsFiltered: response.data})
                    //console.log(`manage users'===>${JSON.stringify(response.data)}`);
                    if(this.state.previousScreen.typeCd.trim() !== 'PRUS'){
                        this.setChecked();
                    }
                } else {
                    this.setState({errorMsg: `Error: There was an error retrieving data. Please try again or contact Customer Service.`})
                }
            }).catch(function (error) {
            console.log('Manage Ftins for User Error:' + error);
        })
    }

    setChecked = () => {
        const selected = [];
        this.state.ftins.forEach( tin => {
                selected.push(tin.ftin)
        })
        if(selected.length > 0) {
            this.handleSetSelected(selected);
        }
    }

    setfilter = (filtered) => {
        console.log( `in fitler set state`)
        if(filtered && filtered.length >= 0)
            this.setState({ftinsFiltered:filtered})

    }

    handleSetSelected = (selected) => {
       
            this.setState({selected: selected})
        
    }

    closeMsgDlg = () => {
        this.setState({showDlgMsg:false})
    }

    handleSave = () => {
        this.setState({isCreateUserEnabled:false})
        const ftins = this.state.ftins;
        this.setState({ftinSelected:[]});
        ftins.forEach(tin => tin.hasIt = false);
        if(this.state.previousScreen.typeCd.trim() === 'PRUS'){
             if(this.state.selected.length === 0){
                this.setState({isCreateUserEnabled: true});
                this.setState({showDlgMsg: true})
                return;
            }
       
            this.state.selected.forEach(tin => {
            const checkTin = ftins.find(ftin => ftin.ftin === tin);
            checkTin.hasIt = true;
            this.state.ftinSelected.push(checkTin.ftin);
          
        })
    }
     
        this.createUser();

    }

    getFormData() {
        const plainPhoneNm = this.state.previousScreen.phone.replace(/[^\d]/g, "");
       
        return {
            userFirstName: this.state.previousScreen.firstName.trim(),
            userLastName: this.state.previousScreen.lastName.trim(),
            userMidName: this.state.previousScreen.midIntl.trim(),
            emailAddr: this.state.previousScreen.email.trim(),
            phoneNum: plainPhoneNm,
            typeCd: this.state.previousScreen.typeCd.trim(),
            grpInfoId: this.state.loggedInUser.grpInfoId,
            mgrUserInfoId: this.state.loggedInUser.id,
            userFtins: this.buildFtins()
           
        }
    }

 

    buildFtins() {
        var index = 0;
        var output=[];
      if(this.state.previousScreen.typeCd.trim() === 'PRUS'){
        this.state.ftinSelected.forEach(tin => {
            var ftins = {};
            ftins.ftin = tin;
            output[index]=ftins;
            index++;
        });
    }

        return output;
    }

    createUser = () => {
        const plainPhoneNm = this.state.previousScreen.phone.replace(/[^\d]/g, "");
        this.setState({plainPhoneNum:plainPhoneNm});
       
        const newUserDetails = this.getFormData();
       AuthenticationService.createNewUser(newUserDetails)
       .then(response => {            
           if(response.status === 200) {
               console.log(`add user data success`)
           }
           this.props.history.push({
            pathname: '/wcpp/ManageUsers'
        })
        
       }).catch((error) => {
      
        console.log(`update user from settings ${error.message}`);
        this.setState({shouldShowLoadingIndicator:false, errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`});
        this.setState({isCreateUserEnabled:true})
        console.log(`update user ${error}`);
    })
     
    }

    handleCancel = () => {
        this.props.history.push('/wcpp/ManageUsers')

    }
    handleChangeRowsPerPage = event => {
        this.setState({page: 0, rowsPerPage: event.target.value})
    }
    
    handlePrevious = () => {
        this.props.history.push({
            pathname: '/wcpp/AddUser', state: {previousScreen: this.state.previousScreen}
        })
       

    }

    render() {


        return(
            <Fragment>

                <div className='App-content ManageTinsForUser'>
                    <h1 className='App-page-label'>
                    Manage Users: Add User: Manage TINs for {this.props.location.state.previousScreen.firstName} {this.props.location.state.previousScreen.lastName}</h1>
                    <div className='App-error App-font' hidden={this.state.errorMsg === ''}>{this.state.errorMsg}</div>
                    <ManageTinsForUserTable tinsFiltered={this.state.ftinsFiltered} setFilter={this.setfilter}
                                            tins={this.state.ftins} setSelected={this.handleSetSelected}
                                            selected={this.state.selected} admin={this.props.location.state.previousScreen.typeCd  === 'PRAD'}/>
                    <Grid>
                        <Button color="primary" disabled={!this.state.isCreateUserEnabled}  onClick={this.handleSave}>
                            CREATE USER
                        </Button>
                        <Button  color="primary"  onClick={this.handlePrevious}>
                            PREVIOUS
                        </Button>
                        <Button  variant="outlined" onClick={this.handleCancel}>
                            CANCEL
                        </Button>
                    </Grid>

                    <Dialog
                        open={this.state.showDlgMsg}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"You must select at least one TIN"}
                        </DialogTitle>

                            <div style={{textAlign:'right'}}>
                            <Button variant="text"   onClick={this.closeMsgDlg} >
                                OK</Button>
                            </div>

                    </Dialog>
                   
                </div>
            </Fragment>

        );

    }
}
export default (AddUserManageTin);