import React, { Component, Fragment } from 'react'
import './ClientList.css'
import AuthenticationService from "../../Services/AuthenticationService";
import ClntLstResponse from '../ClntLstResponse';
import Button from "@material-ui/core/Button";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {Grid} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import GlobalMessage from "../GlobalMessage";
import LoadingMsgBar from "../LoadingMsgBar";
import { isNotEmpty} from '../../utils/Validator.js';
import { compareIgnoreCase } from '../../utils/Utils.js';
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";


window.analytics ={pageName:'Client List'};
class ClientList extends Component {
    validErrorTxt = `An error has occurred. Please address all fields marked with “Error". `;
    constructor(props) {
        super(props);
        this.state = {
            taxIds: [], taxId: '', providers: [], products: [], 
            validMsg: '', providerStates:[],
            provider: '', state: '', product: '', isBusyMsg: '', isNoProd: false,isNoProv: false, lobSelect: 'WC', test: true,
            showResponse: false, taxIdsOptions: [], selectedTaxId: null, providersOptions: [], selectedProvider: null,
            providerStatesOptions: [], selectedState: null, selectedProduct: null, productOptions: [],
            taxIdError:'', providerError:'', stateError:'', productError:'', isProviderDisabled: true, isStateDisabled: true, isProductDisabled: true,
            isSearchEnabled: true
        }
        //this.lobInput = React.createRef();
    }
    componentDidMount() {
        AuthenticationService.getUserFtins(AuthenticationService.getLoggedInUserName())
            .then((response) => {
               // let txIdOptions = [];
                if(null != response.data){
                    response.data.forEach((record) => {
                       // const labeloption={label:`${record.ftin}`, value:`${record.ftin}`}
                       // this.state.taxIdsOptions.push(labeloption);
                       this.state.taxIdsOptions.push(`${record.ftin}`);
                    });                    
                }                
            }).catch(function (error) {
            console.log('Error receiving user ftins:' + error);
        })
       // this.lobInput.current.focus();
    }
    handleLobSelected = (event) => {
        this.handleResetFields()
        this.setState({lobSelect: event.target.value})
    }

    handleTaxIdSelected = (selectedTaxId) => {
        this.setState({ selectedTaxId });
        this.setState({
            showResponse: false, taxId: null,  state: null, product: null, provider: null,
            products: [], providerStates: [], providers: [], selectedProvider: null, providersOptions: [],
            selectedState: null, providerStatesOptions: [], selectedProduct: null, productOptions: [],
            isNoProd: false, isNoProv: false,
            taxIdError:'', providerError:'', stateError:'', productError:'', isProviderDisabled: true, isStateDisabled: true, isProductDisabled: true
        })
       if(selectedTaxId !== null){
            const selected =selectedTaxId;
            this.setState({taxId: selected});
            this.setState({ isBusyMsg: `Please wait while we retrieve data.` , validMsg: ''})
            this.getProviders(selected);
        }
    
    }

    handleProviderSelected = (selectedProvider) => {

        this.setState({
            showResponse: false, provider: null, state: null, product: null, products: [], selectedProduct: null, productOptions: [],
            providerStates: [], selectedState: null, isNoProd: false,selectedProvider: selectedProvider,
            taxIdError:'', providerError:'', stateError:'', productError:'',  isStateDisabled: true, isProductDisabled: true
        })
        if(selectedProvider === null) {
            return;
        }
        const prov = this.state.providers[selectedProvider.value];

        this.setState({provider:prov});
        AuthenticationService.getProvidersState(prov.prvId, prov.prvProviderType)
            .then((response) => {

                if (response.data !== null && response.data[0] === undefined) {
                    this.addErrorMessage("stateError", "Error: States not found");

                } else {
                    let listStatesToSort = response.data;
                    listStatesToSort.sort((a, b) => compareIgnoreCase(a.addState, b.addState));
                    let providerStatesOpt=[];
                    listStatesToSort.forEach((state) => {
                        const labeloption = { label: `${state.addState}`, value: `${state.addState}` };
                        providerStatesOpt.push(labeloption);
                    })

                    this.setState({ providerStatesOptions: providerStatesOpt, isStateDisabled: false,isBusyMsg: '' });

                }
                this.setState({ isBusyMsg: '' });
            }).catch(function (error) {
            this.setState({ isBusyMsg: '' });
            console.log('Error getting Provider States' + error);
        })

        }


    loadProducts = (selectedState) => {
        this.setState({ selectedState });
        this.setState({
            showResponse: false,state: null, product: null, isNoProd: false, products: [],
            isTxV: true, isProvV: true, isProdV: true, isStateV: true, productOptions: [], isProductDisabled: true, selectedProduct:null
        })
        if(selectedState !== null){
         const state = selectedState.value;

            this.setState({ isBusyMsg: `Please wait while we retrieve data.`, state: state, isNoProd: false, products: null,
            productOptions: [], selectedProduct: null, validMsg: '', taxIdError:'', providerError:'', stateError:'', productError:'', isProductDisabled: true})
            AuthenticationService.getProducts(this.state.lobSelect, this.state.provider.prvId, state, this.state.taxId)
             .then((response) => {
                if (response.data !== null && response.data[0] === undefined) {
                    this.setState({ isNoProd: true, products: [], product: null, productOptions: [] });
                    if(this.state.isNoProd === true){
                        this.addErrorMessage("productError", "Error: Product not found");
                    }

                } else {
                    let productOptions = [];
                    this.setState({ products: response.data })
                    response.data.forEach((product) => {
                        productOptions.push({name: product.productName, key: product.productCd})
                    });
                    this.setState({productOptions: productOptions, isProductDisabled: false});

                }
                this.setState({ isBusyMsg: '' })
            }).catch(function (error) {
                console.log('Error getting Providers' + error);
            })
        }
    }
    setSelectedProduct = (selectedProduct) => {
        this.setState({ selectedProduct });
        this.setState({ isNoProd: false,  taxIdError:'', providerError:'', stateError:'', productError:'', showResponse: false, product: null  });
        if(selectedProduct !== null){

            const selected = selectedProduct;
            this.setState({ product: selected })
        }
        
    }

   
    getProviders = (taxId) => {
       
        
        AuthenticationService.getProviders(taxId)
            .then((response) => {
                let isNoProv = true;

                this.setState({ providers: response.data !==null && response.data.length > 0 ?  response.data : null, taxId:taxId})
                if (isNotEmpty(response.data)) {
                    response.data.sort((a, b) => compareIgnoreCase(a.prvName, b.prvName));
                }
                
                response.data.forEach((provider, index) => {
                    const label = `${provider.prvName + ': ' + provider.prvId}`;
                    const val = `${index}`;
                    const labeloption = { label: label, value: val };
                    this.state.providersOptions.push(labeloption);
                     isNoProv = false;
                 });
              
                
                this.setState({ providers: response.data, isBusyMsg: '' ,isNoProv: isNoProv, validMsg: isNoProv === true ?  this.validErrorTxt :'', isProviderDisabled: false})
                if(this.state.isNoProv === true){
                    this.addErrorMessage("providerError", "Error: There are no active providers that are participating in the Coventry Integrated Network.");
                }
            }).catch((error) => {
               console.log('Error GETTING THE PROVIDERS' + error);
               this.setState({ isBusyMsg: '' ,isNoProv:  true , isProviderDisabled: false, validMsg:'Error: An error has occurred. Please contact Customer Service.'});

        })
    }


    handleResetFields = () => {
        this.setState({
            isTxV: true, validMsg: '', providers: null, selectedTaxId: null, providersOptions: [], selectedProvider: null, products: null, providerStates: null, lobSelect: 'WC', showResponse: false,
            isNoProd: false,isNoProv: false, provider: null, state: null, product: null,
            selectedState: null, providerStatesOptions: [], selectedProduct: null, productOptions: [], taxIdError:'', providerError:'', stateError:'', productError:'',
            isProviderDisabled: true, isStateDisabled: true, isProductDisabled: true, isBusyMsg:''
        });
    }

    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }


    handleSubmit = () => {     
        let valid = true
        let messageList = [];
        this.setState({
            validMsg: '', isTxV: true, isProvV: true, isProdV: true,
            isStateV: true, showResponse: false, isBusyMsg:'', taxIdError:'', providerError:'', stateError:'', productError:''
        })

        if (this.state.taxId === null || this.state.taxId === '') {
            //this.setState({taxIdError:"Error: Select Tax ID"})
            messageList.push({ field: "taxIdError", message: "Error: Select Tax ID" });
            valid = false;

        }
        if (this.state.provider === null || this.state.provider === '') {
            messageList.push({ field: "providerError", message: "Error: Select Provider" });
            valid = false;

        }
        
        if (this.state.state === null || this.state.state === '') {
            messageList.push({ field: "stateError", message: "Error: Select State" })
            valid = false;

        }
        if (this.state.product === null || this.state.product === '') {
            messageList.push({ field: "productError", message: "Error: Select Product" });
            valid = false;
        }

        if (valid === true) {            
           if(this.state.showResponse){
            this.setState({isSearchEnabled: true })        
           }else{
            this.setState({isSearchEnabled: false })      
           }
            this.setState({ showResponse: true})            
            
        }else{
            this.showMessages(messageList)            
            this.setState({isSearchEnabled: true })            
        }       
        this.setState({ validMsg: valid === true ? '' : this.validErrorTxt })
    }

    toggleLoadingMessage = (show) =>{
        this.setState({isBusyMsg: show === true? `Please wait while we retrieve data.` : ''})

    }

   handleChangeRadio = event => {
       this.handleResetFields();
       this.setState({lobSelect: event.target.name});

   }

   enableDisableSearch = (enabled) => {
    this.setState({isSearchEnabled : enabled});
    }


    render() {
        return(
        <Fragment>

        <div className="providerSearchInputComponent">
        <h1 className="App-page-label" style={{margin:0}}>Client List</h1>

            <LoadingMsgBar loadMsg={this.state.isBusyMsg}/>

            <GlobalMessage  message={this.state.validMsg} />

            <div className='App-error-font App-note-Field' > Note: Fields marked with
                an asterisk(*) are required.
                </div>
       

        <FormControl component="fieldset">
                            <FormLabel required component="legend">Line of Business</FormLabel>
                            <RadioGroup aria-label="position" defaultValue='WC' name="position" onChange={this.handleChangeRadio} row>
                                <FormControlLabel
                                    value='WC'
                                    control={<Radio color="primary" />}
                                    label="Workers' Comp"
                                    labelPlacement="end"
                                    name='WC'
                                />
                                <FormControlLabel
                                    name='AUTO'
                                    value='AUTO'
                                    control={<Radio color="primary" />}
                                    label="Auto"
                                    labelPlacement="end"

                                />
                            </RadioGroup>
                        </FormControl>

                        <Grid container direction="column" >
                        <Grid item xs={12} md={5}>          
                         <Autocomplete
                            value={this.state.selectedTaxId} options={this.state.taxIdsOptions}
                            onChange={(event, newValue) => {
                                this.handleTaxIdSelected(newValue);
                            }}                            
                            renderInput={(params) => <TextField {...params} label="Tax ID *" id="taxId" variant="outlined" 
                            helperText={this.state.taxIdError} FormHelperTextProps={{
                                style: { color: '#B0170C' }
                              }}/>}
                        />
                        </Grid>

                        <Grid item xs={12} md={5}>                 
                         <Autocomplete disabled={this.state.isProviderDisabled}
                            value={(this.state.selectedProvider)} options={this.state.providersOptions}
                            onChange={(event, newValue) => {
                                this.handleProviderSelected(newValue);
                            }}
                            getOptionLabel={(provider) => `${provider.label}`}
                            id="providerId"
                            renderInput={(params) => <TextField {...params} label="Provider *" variant="outlined" 
                            helperText={this.state.providerError} FormHelperTextProps={{
                                style: { color: '#B0170C' }
                              }}/>}
                        />
                        </Grid>
                       
                        <Grid item xs={12} md={5}>
                        <Autocomplete disabled={this.state.isStateDisabled}
                            value={this.state.selectedState} options={this.state.providerStatesOptions}
                            onChange={(event, newValue) => {
                                this.loadProducts(newValue);
                            }}
                            getOptionLabel={(state) => `${state.label}`}
                            id="stateId"
                            renderInput={(params) => <TextField {...params} label="State *" variant="outlined" helperText={this.state.stateError} FormHelperTextProps={{
                                style: { color: '#B0170C' }
                              }}/>}
                        />
                        </Grid>

                        <Grid item xs={12} md={5}>
                        <Autocomplete disabled={this.state.isProductDisabled}
                            value={this.state.selectedProduct} options={this.state.productOptions}
                            onChange={(event, newValue) => {
                               this.setSelectedProduct(newValue);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Product *" variant="outlined" helperText={this.state.productError} FormHelperTextProps={{
                               style: { color: '#B0170C' }
                            }}/>}
                        />
                        </Grid>

                        </Grid>

                        <div className="buttonBar">
                            <Button disabled={!this.state.isSearchEnabled} onClick={this.handleSubmit} aria-label='Client List Search: SEARCH Button'>Search</Button>
                            <Button variant="outlined" onClick={this.handleResetFields} aria-label='Client List Search:RESET Button'>Reset</Button>
                        </div>
                        </div>

                        <div className='Manage-users-tbl-div'>
                        { /* <ClntLstResponse lob='WC' provId='100674695' provState='NY' prod='WCINTEGRATEDNETWOR1K' provTin='205659485' toggleLoadMsg={this.toggleLoadingMessage}/>*/
                        /*this.state.clients != null && this.state.clients.length > 0 ? <ClientListResponse rows={this.state.clients}/> : <h3>No clients found</h3> :''*/
                         this.state.showResponse === true ? 
                         <div> 

                           
                              <ClntLstResponse lob={this.state.lobSelect} provId={this.state.provider.prvId} provState={this.state.state}
                              prod={this.state.product} provTin={this.state.taxId} toggleLoadMsg={this.toggleLoadingMessage}
                              enableDisableSearch={this.enableDisableSearch} />
                              </div>
                              : ''
                      }
                 
                        </div>
            </Fragment>

);
      }
  }
  export default ClientList;